.video-player {
  width: auto;
  display: inline-block;
  height: 100%;
  position: relative;
}

.video-player__controls {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 3;
  height: 40px;
}

.video-player__controls__inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  flex-wrap: wrap;

  & svg {
    & * {
      fill: $red;
    }
  }
  flex-wrap: nowrap;
}

.video-player__button--play-pause {
  display: block;
  position: relative;
  padding: 6px;
  -webkit-appearance: none;
  border: none;
  background-color: transparent;
  border-radius: 0;
  width: 30px;
  height: 30px;

  &.play {
    &::after {
      content: '';
      display: block;
      border: 12px solid $red;
      position: absolute;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right-color: transparent;
      border-top-width: 8px;
      border-bottom-width: 8px;
      width: 12px;
      height: 12px;
      left: 9px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  &.pause {
    &::after {
      content: '';
      display: block;
      border-left: 4px solid $red;
      border-right: 4px solid $red;
      position: absolute;
      width: 12px;
      height: 15px;
      left: 9px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}

.video-player__input--time__wrapper {
  width: calc(100% - 30px);
}

.video-player__input--volume__wrapper {
  width: calc(100% - 30px);
}

.video-player__time__wrapper,
.video-player__volume__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 50px);
}

.video-player__volume__wrapper {
  padding-left: 20px;
}

.video-player__volume__wrapper {
  width: 25%;
}
