$padding--horizontal: 12px;
$number-of-cols: 12;

$xs: 500px;
$sm: 768px;
$md: 1200px;
$lg: 1500px;
$xl: 1920px;

@mixin xs {
  @media (min-width: #{$xs}) { @content; }
}
@mixin sm {
  @media (min-width: #{$sm}) { @content; }
}
@mixin md {
  @media (min-width: #{$md}) { @content; }
}
@mixin lg {
  @media (min-width: #{$lg}) { @content; }
}
@mixin xl {
  @media (min-width: #{$xl}) { @content; }
}
@mixin xxl {
  @media (min-width: #{$xxl}) { @content; }
}

@mixin below-xs {
  @media (max-width: #{$xs - 1}) { @content; }
}
@mixin below-sm {
  @media (max-width: #{$sm - 1}) { @content; }
}
@mixin below-md {
  @media (max-width: #{$md - 1}) { @content; }
}
@mixin below-lg {
  @media (max-width: #{$lg - 1}) { @content; }
}
@mixin below-xl {
  @media (max-width: #{$xl - 1}) { @content; }
}
@mixin below-xxl {
  @media (max-width: #{$xxl - 1}) { @content; }
}

@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container-fluid {
  width: 100%;
  padding-right: $padding--horizontal;
  padding-left: $padding--horizontal;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: #{0 - $padding--horizontal};
  margin-left: #{0 - $padding--horizontal};
}

.columns__wrapper-eq-height {
  display:  -webkit-box;
  display: -webkit-flex;
  display:  -ms-flexbox;
  display:         flex;
}

@mixin col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: $padding--horizontal;
  padding-left: $padding--horizontal;
}

.col,
.col-xxs-auto, .col-xxs-1, .col-xxs-2, .col-xxs-3, .col-xxs-4, .col-xxs-5, .col-xxs-6, .col-xxs-7, .col-xxs-8, .col-xxs-9, .col-xxs-10, .col-xxs-11, .col-xxs-12, .col-xxs-13, .col-xxs-14, .col-xxs-15, .col-xxs-16, .col-xxs-17, .col-xxs-18, .col-xxs,
.col-xs-auto, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16, .col-xs-17, .col-xs-18, .col-xxs,
.col-sm-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-xxs,
.col-md-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md,
.col-lg-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg,
.col-xl-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xxs,
{
  @include col;
}


@for $i from 1 through $number-of-cols {
  .col-xxs-#{$i} {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 #{(100% / $number-of-cols) * $i};
    flex: 0 0 #{(100% / $number-of-cols) * $i};
    max-width: #{(100% / $number-of-cols) * $i};
  }

  .order-xxs-#{$i - 1} {
    -webkit-box-ordinal-group: $i;
    -ms-flex-order: #{$i - 1};
    order: #{$i - 1};
  }

  .offset-xxs-#{$i - 1} {
    margin-left: #{(100% / $number-of-cols) * ($i - 1)};
  }
}

@for $i from 1 through $number-of-cols {
  @include xs {
    .col-xs-#{$i} {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 #{(100% / $number-of-cols) * $i};
      flex: 0 0 #{(100% / $number-of-cols) * $i};
      max-width: #{(100% / $number-of-cols) * $i};
    }

    .order-xs-#{$i - 1} {
      -webkit-box-ordinal-group: $i;
      -ms-flex-order: #{$i - 1};
      order: #{$i - 1};
    }

    .offset-xs-#{$i - 1} {
      margin-left: #{(100% / $number-of-cols) * ($i - 1)};
    }
  }
}
@for $i from 1 through $number-of-cols {
  @include sm {
    .col-sm-#{$i} {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 #{(100% / $number-of-cols) * $i};
      flex: 0 0 #{(100% / $number-of-cols) * $i};
      max-width: #{(100% / $number-of-cols) * $i};
    }

    .order-sm-#{$i - 1} {
      -webkit-box-ordinal-group: $i;
      -ms-flex-order: #{$i - 1};
      order: #{$i - 1};
    }

    .offset-sm-#{$i - 1} {
      margin-left: #{(100% / $number-of-cols) * ($i - 1)};
    }
  }
}
@for $i from 1 through $number-of-cols {
  @include md {
    .col-md-#{$i} {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 #{(100% / $number-of-cols) * $i};
      flex: 0 0 #{(100% / $number-of-cols) * $i};
      max-width: #{(100% / $number-of-cols) * $i};
    }

    .order-md-#{$i - 1} {
      -webkit-box-ordinal-group: $i;
      -ms-flex-order: #{$i - 1};
      order: #{$i - 1};
    }

    .offset-md-#{$i - 1} {
      margin-left: #{(100% / $number-of-cols) * ($i - 1)};
    }
  }
}
@for $i from 1 through $number-of-cols {
  @include lg {
    .col-lg-#{$i} {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 #{(100% / $number-of-cols) * $i};
      flex: 0 0 #{(100% / $number-of-cols) * $i};
      max-width: #{(100% / $number-of-cols) * $i};
    }

    .order-lg-#{$i - 1} {
      -webkit-box-ordinal-group: $i;
      -ms-flex-order: #{$i - 1};
      order: #{$i - 1};
    }

    .offset-lg-#{$i - 1} {
      margin-left: #{(100% / $number-of-cols) * ($i - 1)};
    }
  }
}
@for $i from 1 through $number-of-cols {
  @include xl {
    .col-xl-#{$i} {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 #{(100% / $number-of-cols) * $i};
      flex: 0 0 #{(100% / $number-of-cols) * $i};
      max-width: #{(100% / $number-of-cols) * $i};
    }

    .order-xl-#{$i - 1} {
      -webkit-box-ordinal-group: $i;
      -ms-flex-order: #{$i - 1};
      order: #{$i - 1};
    }

    .offset-xl-#{$i - 1} {
      margin-left: #{(100% / $number-of-cols) * ($i - 1)};
    }
  }
}