html, body, .App {
  width: 100vw;
  height: 100vh;

  background-color: $bg;
  color: $text-color;
	font-family: "Untitled Serif", serif;
}

#root {
  top:0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  width: 100vw;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  -webkit-tap-highlight-color: transparent;
}

// *::-webkit-scrollbar {
//   display: none;
// }

button {
  cursor: pointer;
  user-select: none;
}

img {
  user-select: none;
}

.blur {
  -webkit-filter: blur(1px);
  filter: blur(1px);
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 0;

  & li {
    list-style: none;
    display: block;
  }
}

pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}