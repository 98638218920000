.header {
	width: 100%;
	height: 80px;
	padding-top: 5px;

	@include sm {
		height: 170px;
		padding-top: 20px;
	}

	@include md {
		height: 140px;
		padding-top: 30px;
	}
}

.header-title__container {
	display: inline-block;
	height: auto;
	width: 100%;

	@include sm {
		width: 950px;
	}
}

.header-title__text {
	color: $text-color;
	font-size: 2rem;
	font-family: "Untitled Serif", serif;
	padding-left: 0px;

	user-select: none;

	@include sm {
		font-size: 5rem;
		padding-left: 20px;
	}
}






.header-links__container {
	position: absolute;
	height: 28px;
	width: auto;
	top: 40px;
	right: 5px;
	left: auto;
	display: flex;


	@include sm {
		height: 50px;
		width: auto;
		top: 120px;
		right: 5px;
	}

	@include md {
		width: auto;
		top: 60px;
		right: 40px;

	}
}

.header-link__button {
	display: inline-block;
	height: auto;
	width: auto;
	margin-right: 10px;
	margin-top: 5px;
	background-color: $bg;
	user-select: none;
	user-select: none;

	border: 1px solid $text-color;
	border-radius: 999px;
	cursor: pointer;

	@include sm {
		border: 2px solid $text-color;
	}

	.header-link__text {
		color: $text-color;
		font-size: 0.8rem;
		font-family: "Untitled Serif";
		padding: 3px;
		padding-left: 10px;
		padding-right: 10px;


		@include sm {
			font-size: 1.5rem;
			padding: 5px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&:hover {
		background-color: $text-color;

		.header-link__text {
			color: white;
		}
	}
}
