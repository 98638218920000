// @font-face {
//   font-family: 'MonumentRegular';
//   src: url('../fonts/ABCMonumentGrotesk-Regular-Trial.woff') format('woff'),
//        url('../fonts/ABCMonumentGrotesk-Regular-Trial.woff2') format('woff2');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

body, html {
  font-family: 'MonumentRegular', sans-serif;
}

@mixin button--plain {
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
}

.button--plain {
  @include button--plain;
}

@mixin font--regular {
  font-family: 'M', sans-serif;
  text-transform: uppercase;
}

.font--regular {
  @include font--regular;
}

@mixin font--mono {
  font-family: 'MonumentMonoRegular', sans-serif;
}

.font--mono {
  @include font--mono;
}
