.tag-selection__container {
	height: 35px;
	padding-left: 15px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: $bg;
	position: -webkit-sticky;
	position: sticky;
	top: -1px;

	@include sm {
		padding-left: 40px;
		height: 45px;
	}

	.tag-link__button {
		position: absolute;
		height: auto;
		width: auto;
		margin-right: 10px;
		margin-top: 5px;
		background-color: $bg;
		right: 5px;
		top: 3px;
		opacity: 0;
		transition: opacity 0.3s ease;
		
		border: 1px solid $text-color;
		border-radius: 999px;

		@include sm {
			border: 2px solid $text-color;
			right: 40px;
			top: 0px;
		}

		.tag-link__text {
			color: $text-color;
			font-size: 0.8rem;
			font-family: "Untitled Serif";
			padding: 3px;
			padding-left: 10px;
			padding-right: 10px;


			@include sm {
				font-size: 1.5rem;
				padding: 5px;
				padding-left: 15px;
				padding-right: 15px;
			}
		}

		&:hover {
			cursor: pointer;
			background-color: $text-color;

			.header-link__text {
				color: white;
			}
		}
	}

	&.sticky {
		.tag-link__button {
			opacity: 1;
		}
	}
}

.tag-selection__button {
	display: inline-block;
	height: 100%;
	width: auto;

	margin-left: 0px;
	margin: auto;
	background-color: $bg;
	border-radius: 999px;

	@include sm {
		padding: 10px;
		margin-left: 5px;
	}

	.tag-selection__button__text {
		font-size: 0.8rem;
		font-family: "Untitled Serif"; 
		color: $text-color;

		padding-left: 8px;
		padding-right: 8px;
		position: relative;
		bottom: -5px;

		@include sm {
			font-size: 1.5rem;
			padding-left: 10px;
			padding-right: 10px;
			bottom: 5px;
		}
	}

	&:hover {
		background-color: $text-color;
		cursor: pointer;

		.tag-selection__button__text {
			color: $bg;
		}

	}

	&.active {
		background-color: $text-color;
		cursor: pointer;

		.tag-selection__button__text {
			color: $bg;
		}

	}
}


