
.posts-board {
	margin-top: 50px;
	width: 95vw;
	margin-left: 2.5vw;
	column-count: 1;
	column-gap: 3px;

	@include sm {
		column-count: 2;
		column-gap: 5px;
	}

	@include md {
		column-count: 3;
		column-gap: 10px;
	}

}

.post__text {
	margin-top: 10px;
	color: $text-color;
	font-size: $body;
	font-family: "Untitled Serif";
}

.post__author {
	text-align: right;
	margin-top: 10px;
	color: $text-color;
	font-size: $body;
	font-family: "Untitled Serif";
}

.post__expand {
	margin-top: 3px;
	color: $text-color;
	font-size: $body;
	font-family: "Untitled Serif";
	text-decoration: underline;

	&:hover {
		cursor: pointer;
	}
}




.image-post__container {
	width: 100%;
	height: auto;
	padding: 10px;
	background-color: white;
	display: inline-block;
	margin-bottom: 10px;
}

.image-post__image {
	width: 100%;
	height: auto;
}





.text-post__container {
	width: 100%;
	height: auto;
	padding: 10px;
	background-color: white;
	display: inline-block;
	margin-bottom: 10px;
}








.video-post__container {
	width: 100%;
	height: auto;
	padding: 10px;
	background-color: white;
	display: inline-block;
	margin-bottom: 10px;
}

.video-post__video {
	width: 100%;
	height: auto;
}




.audio-post__container {
	width: 100%;
	height: auto;
	padding: 10px;
	background-color: white;
	display: inline-block;
	margin-bottom: 10px;
}

.audio-post__player {
	width: 100%;
	height: 50px;
}

.playlist-post__container iframe {
	display: block;
	width: 100%;
	border-radius: 20px;
}
