.intro-box {
	position: fixed;
	width: 100%;
	max-width: 100vw;
	height: calc(100vh - 80px);
	top: 80px;
	background-color: $bg;
	overflow-y: scroll;
	display: flex;
	flex-wrap: wrap;

	@include sm {
		height: calc(100vh - 170px);
		top: 170px;
	}

	@include md {
		height: calc(100vh - 140px);
		top: 140px;
		flex-wrap: nowrap;
	}
}

.intro-text__container {
	width: 90%;
	padding-left: 5%;

	@include sm {
		width: 90%;
		padding-left: 5%;
	}

	@include md {
		width: 60%;
		padding-left: 45px;
	}

	p {
		color: $text-color;
		font-size: 1rem;
		font-family: "Untitled Serif";
		padding-top: 20px;

		@include sm {
			font-size: 1.8rem;
			padding-top: 20px;
		}
	}
}

.intro-image__container {

	padding: 20px;
	width: 100%;

	@include md {
		width: 40%;
	}

	& .intro-image {
		display: block;
		max-width: 100%;
		max-height: 100%;
		width: 400px;

		@include md {
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.intro-link__button {
	width: 80px;
	text-align: center;
	height: auto;
	padding: 5px;
	margin-top: 25px;
	margin-bottom: 10px;
	margin-left: -10px;
	background-color: $text-color;
	border-radius: 999px;

	@include sm {
		width: 110px;
		padding: 8px;
	}

	.intro-link__text {
		color: white;
		font-size: 1rem;
		font-family: "Untitled Serif";

		@include sm {
			font-size: 1.8rem;
		}

	}
}