.upload-box {
	position: fixed;
	width: 100%;
	max-width: 100vw;
	height: calc(100vh - 80px);
	top: 80px;
	background-color: $bg;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	padding-top: 40px;
	padding-bottom: 180px;
	line-height: 1.4em;

	@include sm {
		height: calc(100vh - 170px);
		top: 170px;
	}

	@include md {
		height: calc(100vh - 140px);
		top: 140px;
	}

	& form {
		width: calc(100vw - 40px);
		max-width: 600px;
		margin-left: 20px;
	}

	&.processing {
		& form {
			transition: opacity 0.2s ease-in-out;
			opacity: 0.6;
			pointer-events: none;
		}
	}
}

*::-webkit-file-upload-button,
.upload {
	font-family: "Untitled Serif", serif;
	user-select: none;
	background-color: transparent;
	-webkit-appearance: none;
	color: $text-color;
	font-size: 0.8rem;
	padding: 3px;
	padding-left: 10px;
	padding-right: 10px;
	cursor: pointer;


	@include sm {
		font-size: 1.5rem;
		padding: 5px;
		padding-left: 15px;
		padding-right: 15px;
	}


	border: 1px solid $text-color;
	border-radius: 999px;

	@include sm {
		border: 2px solid $text-color;
	}
}

.upload-box__intro p {
	margin-bottom: 1em;

	& a {
		text-decoration: underline;
	}
}

.upload-form__file {
	display: block;
	background-color: transparent;
	margin-bottom: 2em;
}

.upload-form__label {
	display: block;
	margin-bottom: 2em;
}

.upload-form__input {
	display: block;
	-webkit-appearance: none;
	border: none;
	font-family: inherit;
	border-bottom: 1px solid $text-color;
	margin-bottom: 2em;
	background-color: transparent;
	width: 100%;

	@include sm {
		border-bottom-width: 2px;
	}
}

.upload-form__message {
	border-radius: 0;
	border: 1px solid $text-color;
	background-color: transparent;
	margin-bottom: 2em;
	width: 100%;
	font-family: inherit;

	@include sm {
		border-width: 2px;
	}
}

.upload-form__error,
.upload-form__success {
	margin-bottom: 2em;
	margin-top: 2em;
	padding: 1em;
	border: 1px solid $text-color;

	@include sm {
		border-width: 2px;
	}

	& a {
		text-decoration: underline;
	}
}