// *, body, html, p, div {
// }

// h1, h2, h3, h4, h5, h6 {
//   font-weight: normal;
//   font-size: 1rem;
// }

@font-face {
  font-family: "Untitled Serif";
  src: url("../fonts/untitled-serif-web-regular.woff") format("woff"),
        url("../fonts/untitled-serif-web-regular.woff2") format("woff2"),
        url("../fonts/untitled-serif-web-regular.eot") format("eot");
}

$body: 1.3rem;

.link--ui {
  color: $red;
  font-size: inherit;
  border: none;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  display: block;
  -webkit-transition: -webkit-filter 0.6s ease-in-out, color 0.6s ease-in-out;
  transition: filter 0.6s ease-in-out, color 0.6s ease-in-out;
  -webkit-filter: blur(0px);
  filter: blur(0px);

  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);

  &:focus,
  &:hover {
    outline: none;
    -webkit-filter: blur(4px);
    filter: blur(4px);

    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }
}
