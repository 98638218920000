.help-box{
	position: fixed;
	width: 100%;
	max-width: 100vw;
	height: calc(100vh - 80px);
	top: 80px;
	background-color: $bg;
	overflow: hidden;

	@include sm {
		height: calc(100vh - 170px);
		top: 170px;
	}

	@include md {
		height: calc(100vh - 140px);
		top: 140px;
	}
}

.help-text__container {
	width: 90%;
	padding-left: 5%;
	padding-top: 20px;

	@include sm {
		width: 90%;
		padding-left: 45px;
	}

	@include md {
		width: 60%;
		padding-left: 45px;
	}

	p {
		color: $text-color;
		font-size: 1rem;
		font-family: "Untitled Serif";
		padding-top: 20px;

		a {
			text-decoration: underline;
		}

		@include sm {
			font-size: 2rem;
			padding-top: 20px;
		}
	}
}
